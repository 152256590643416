import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { trackingGA } from '@merchx-v2/helpers'
import { navigate } from 'utils/navigate'
import { Button, Footer, Header, Input, Notification, SEO } from "components";

import {
  actionCreators as actionHomeCreators,
  selectMenu,
} from "state/HomePage/reducer";
import * as actions from "state/LoginPage/actions";
import { selectors } from "state/LoginPage/reducer";
import * as styles from "./ResetPasswordPage.module.scss";

const isPasswordValid = (password) => {
  const trimmedPassword = password?.trim();
  let isValid = false;
  let error = "Please input password";
  const reg = /^((?=.*\d)(?=.*[A-Z])(?=.*\W).{6,})$/;
  isValid = reg.test(trimmedPassword);
  if (!isValid) {
    error =
      "Password must contain at least 6 symbols: one upper letter, one number, and one special character.";
  }

  return {
    isValid,
    error,
  };
};

const ResetPasswordPage = (props) => {
  const {
    loginState,
    menu,
    location,

    resetPassword,
    resetMenuFilters,
    setMenuCollection,
  } = props;

  const params = new URLSearchParams(location.search);
  const token = params.get("token");

  const [isResetPasswordCompleted, setIsResetPasswordCompleted] = useState(
    false
  );
  const [isShowErrors, setIsShowErrors] = useState(false);
  const [notification, showNotification] = useState();
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  useEffect(() => {
    trackingGA()
  }, [])

  useEffect(() => {
    showNotification(loginState.UIState.error);
  }, [loginState.UIState.error]);

  const handleCollectionClicked = (collection) => {
    setMenuCollection(collection);
  };

  const isFormValid = () => {
    let result = false;

    if (
      token &&
      password === repeatPassword &&
      isPasswordValid(password).isValid
    ) {
      result = true;
    }

    if (!token) {
      showNotification("Invalid token. Form can't be used.");
    }

    return result;
  };

  const handleReset = () => {
    resetMenuFilters();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsShowErrors(true);
    if (isFormValid()) {
      const { payload: isResetPassword } = await resetPassword({
        token,
        password,
      });

      if (isResetPassword) {
        setIsResetPasswordCompleted(isResetPassword);
      }
    }
  };

  if (isResetPasswordCompleted) {
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <Header
            menu={menu}
            onCollectionClicked={handleCollectionClicked}
            onResetCollection={handleReset}
            isNeedDescription={true}
          />

          <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.title}>Reset Password</div>

            <p className={styles.description}>
              Thank you. Password Reset was successful.
            </p>

            <p className={styles.description}>
              Return to{" "}
              <span
                className={styles.link}
                onClick={() => navigate("/login?status=resetSuccessful")}
              >
                Log In &gt;
              </span>
            </p>
          </form>
        </div>

        <Notification message={notification} />
        <div className={styles.footerContainer}>
          <Footer handleReset={handleReset} />
        </div>
      </div>
    );
    // navigate("/login?status=resetSuccessful");
  }

  return (
    <div className={styles.container}>
      <SEO pathname='/reset-password' />
      <div className={styles.content}>
        <Header
          menu={menu}
          onCollectionClicked={handleCollectionClicked}
          onResetCollection={handleReset}
          isNeedDescription={true}
        />

        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.title}>Reset Password</div>

          <p className={styles.description}>
            Please enter the new password that you would like to use to access
            your account.
          </p>

          <div className={styles.inputContainer}>
            <Input
              placeholder="Password*"
              onChange={setPassword}
              isValid={isPasswordValid(password).isValid}
              isShowErrors={isShowErrors}
              errorMessage={isPasswordValid(password).error}
              bigInput
              inputType="password"
            />
          </div>

          <div className={styles.inputContainer}>
            <Input
              placeholder="Repeat Password*"
              onChange={setRepeatPassword}
              isValid={repeatPassword === password ? true : false}
              isShowErrors={isShowErrors}
              errorMessage="Password does not match"
              bigInput
              inputType="password"
            />
          </div>

          <div className={styles.button}>
            <Button
              value="Submit"
              loading={loginState.UIState.isLoading}
              className="buttonMedium"
              customAction={handleSubmit}
            />
          </div>
        </form>
      </div>

      <Notification message={notification} />
      <div className={styles.footerContainer}>
        <Footer handleReset={handleReset} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loginState: selectors.selectLoginState(state),
  menu: selectMenu(state),
});

const mapDispatchToProps = {
  resetPassword: actions.resetPassword,

  resetMenuFilters: actionHomeCreators.resetMenuFilters,
  setMenuCollection: actionHomeCreators.setMenuCollection,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
